import customStamp from "../../assets/icons/post-stamp.svg";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faRightFromBracket,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";
import { Popover } from "react-tiny-popover";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthCtx";
import { api } from "../../api/api";
import { toast } from "react-toastify";
import BuyStampsModal from "./BuyStampsModal";

import userImage from "../../assets/icons/user.svg";
import plusIcon from "../../assets/icons/plus.svg";

const Header = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const AuthCtx = useContext(AuthContext);
  const [userName, setUserName] = useState("");
  const [isBuyStampsModalOpen, setIsBuyStampsModalOpen] = useState(false);

  useEffect(() => {
    api
      .get("/auth/getUser", {
        headers: {
          Authorization: `Bearer ${AuthCtx.userToken}`,
        },
      })
      .then((data) => {
        const user = data?.data?.user?.user_info;
        setUserName(user?.firstName + " " + user?.birthName);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, [AuthCtx.userToken]);

  return (
    <>
      {isBuyStampsModalOpen && (
        <BuyStampsModal
          onClose={() => setIsBuyStampsModalOpen(false)}
          token={AuthCtx.userToken}
        />
      )}
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-logo">ByeByeRegrets</h1>
        <div className="flex gap-10 items-center text-xl">
          {/* Left section with stamp and button */}
          <div className="flex gap-2 items-center">
            <img width={30} height={30} src={customStamp} alt="Stamp Icon" />
            {AuthCtx.totalStamps - AuthCtx.usedStamps}
            <button
              className="text-pink text-2xl"
              onClick={() => setIsBuyStampsModalOpen(true)}
            >
              <img src={plusIcon} className="w-6 h-6" alt="Plus Icon" />
            </button>
          </div>

          {/* Popover for User Icon */}
          <Popover
            isOpen={isPopoverOpen}
            position={["bottom"]} // Ensure it's positioned at the bottom
            padding={10} // Add padding for better positioning
            align="center" // Aligns the popover horizontally to the center of the trigger
            onClickOutside={() => setIsPopoverOpen(false)} // Close when clicked outside
            content={
              <div className="py-2 mt-16 mr-[-30px] w-48 bg-white rounded-md shadow-lg">
                <div className="flex gap-2 items-center p-2 hover:bg-gray-100 cursor-pointer">
                  <FontAwesomeIcon color="#545454" icon={faUser} />
                  <p className="font-josefin">
                    {userName.slice(0, 12)}
                    {userName.length > 12 && "..."}
                  </p>
                </div>
                <button
                  onClick={() => {
                    AuthCtx.logout();
                    window.location.href = "/";
                  }}
                  className="flex gap-2 w-full items-center text-red p-2 hover:bg-gray-100 cursor-pointer"
                >
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  <p className="font-josefin">Se déconnecter</p>
                </button>
              </div>
            }
          >
            {/* Trigger for the Popover */}
            <div
              className="flex items-center cursor-pointer"
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            >
              <img src={userImage} className="w-6 h-6" alt="User Icon" />
            </div>
          </Popover>
        </div>
      </div>
    </>
  );
};

export default Header;
