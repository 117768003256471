import React, { useEffect, useState } from "react";

import AuthContext from "../contexts/AuthCtx";
import { ClipLoader } from "react-spinners";
import Header from "../components/Dashboard/Header";
import LetterIcon from "../components/Dashboard/LetterIcon";
import Outbox from "../components/Dashboard/Outbox";
import { api } from "../api/api";
import { toast } from "react-toastify";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const AuthCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [letters, setLetters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (AuthCtx.userToken) {
      setIsLoading(true);
      api
        .get("letter/all", {
          headers: {
            Authorization: `Bearer ${AuthCtx.userToken}`,
          },
        })
        .then((data) => {
          setLetters(data.data.letters);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [AuthCtx.userToken]);

  const addLetterHandler = () => {
    api
      .post("letter/create", { title: "Sans titre" })
      .then((res) => {
        setIsLoading(false);
        navigate(`/letter/${res.data.id}`);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="container mx-auto px-10 py-5">
      {isLoading && (
        <div className="w-100 h-screen flex justify-center items-center">
          <ClipLoader loading={isLoading} color="#CAB9B9" size={40} />
        </div>
      )}
      {!isLoading && (
        <>
          <Header />
          <Outbox />
          <div className="flex justify-center items-center mt-3 w-full">
            <h2 className="font-amaranth text-3xl text-primaryText">
              Mes Lettres
            </h2>
          </div>
          <>
            <div className="mt-5 md:max-w-none gap-2 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-10 2xl:gap-[8.5rem] mx-auto">
              <button
                onClick={addLetterHandler}
                className="shadow-lg rounded-md w-full h-auto md:w-[280px] md:h-[410px] bg-primaryBack text-primaryText font-amaranth text-2xl flex justify-center items-center"
              >
                Nouvelle lettre
              </button>
              {letters.map(({ _id, title, content }) => {
                return (
                  <LetterIcon
                    key={_id}
                    id={_id}
                    title={title}
                    content={content}
                  />
                );
              })}
            </div>
          </>
        </>
      )}
    </div>
  );
}

export default Dashboard;
