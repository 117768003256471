import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Header from "../../components/Home/Header";
import Footer from "../../components/Home/Footer";

import Switch from "react-switch";

import styles from "./AllUsers.module.css";
import { api } from "../../api/api";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthCtx";
import { ClipLoader } from "react-spinners";
import moment from "moment/moment";

const columns = [
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Letters",
    selector: (row) => row.letters,
  },
  {
    name: "isActive",
    selector: (row) => row.isActive,
  },
  {
    name: "Created At",
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
  },
];

function AdminPanel() {
  const [users, setUsers] = useState([]);
  const [changed, setChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const AuthCtx = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await api.get("admin/getUsers", {
          params: {
            certified: true,
          },
          headers: {
            Authorization: `Bearer ${AuthCtx.userToken}`,
          },
        });
        setUsers(data.data.users);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };
    fetchData();
  }, [AuthCtx.userToken, changed]);

  const switchChangeHandler = async (isActive, id) => {
    try {
      await api.put(
        `admin/changeStatus/${id}`,
        { isActive },
        {
          headers: {
            Authorization: `Bearer ${AuthCtx.userToken}`,
          },
        }
      );
      setChanged((prev) => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  const data = users.map((obj) => {
    return {
      ...obj,
      isActive: (
        <Switch
          onChange={(e) => {
            switchChangeHandler(e, obj._id);
          }}
          checked={obj.isActive}
          className="react-switch"
        />
      ),
      createdAt: obj.createdAt.split("T")[0],
    };
  });

  return (
    <>
      <Header />
      <div className={styles.dataTableContainer}>
        {isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <ClipLoader loading={isLoading} color="#ae67fa" size={40} />
          </div>
        )}
        {!isLoading && (
          <DataTable
            title="My Data Table"
            columns={columns}
            data={data}
            className={styles.dataTable}
            noHeader
            highlightOnHover
            pointerOnHover
            pagination
            customStyles={{
              rows: {
                style: {
                  fontSize: "14px",
                },
              },
              headCells: {
                style: {
                  fontSize: "16px",
                  color: "#3e4581",
                  fontWeight: "600",
                },
              },
            }}
          />
        )}
      </div>
      <Footer />
    </>
  );
}

export default AdminPanel;
