import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function LegalInfo() {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const nextHandler = () => {
    navigate("/certification");
  };

  const checkBoxHandler = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div className="bg-[#FAF1F1] min-h-screen w-full flex justify-center items-center">
      <div className="pt-5 lg:px-20 px-6 w-full lg:w-[800px] bg-white h-max py-10 lg:min-h-[100vh] flex flex-col justify-center items-center">
        {/* Title Section */}
        <h3 className=" text-[#775454B2] text-lg lg:text-2xl font-bold mb-5 2xl:mb-10  font-amaranth">
          Création du compte - 1/2
        </h3>
        <h2 className="text-[#775454B2] text-left self-start text-2xl  lg:text-3xl mb-5 2xl:mb-10 font-amaranth">
          Dispositions légales
        </h2>
        
        {/* Form Content */}
        <div className="form-group w-full self-start flex flex-col gap-5 2xl:gap-10">
          <p className="font-josefin text-[#545454] text-sm lg:text-base">
            En vertu de la loi informatique et libertés de 1978, nous souhaitons
            vous informer de manière claire et transparente sur la manière dont
            vos données personnelles sont gérées et utilisées dans le cadre de
            la création de votre compte.
            <br />
            <br />
            En créant votre compte, vous consentez explicitement à l'utilisation
            des informations que vous nous avez fournies dans le but de vérifier
            votre statut vital auprès des administrations publiques. Nous tenons
            à vous assurer que ces données demeurent strictement confidentielles
            et ne seront en aucun cas utilisées à d'autres fins que celles
            précisées.
            <br />
            <br />
            De plus, conformément aux dispositions de la loi mentionnée
            précédemment, nous sollicitons votre consentement explicite pour la
            possibilité de traiter les lettres numériques que vous avez rédigées
            sur notre plateforme. Ces lettres seront soigneusement imprimées,
            scellées et envoyées en recommandé par voie postale à leurs
            destinataires désignés après votre décès.
            <br />
            <br />
            Il est important de noter que l'envoi de vos lettres à leurs
            destinataires ne sera effectué qu'après confirmation de votre décès
            par les administrations publiques, garantissant ainsi la
            confidentialité et le respect de vos volontés posthumes.
          </p>

          {/* Checkbox */}
          <div className="flex items-start">
            <input
              type="checkbox"
              onChange={checkBoxHandler}
              id="check"
              name="check"
              className="mr-2 mt-1 md:h-6 md:w-6"
            />
            <label htmlFor="check" className="text-[#4D5C64] font-josefin font-light">
              En cochant cette case, vous acceptez les dispositions relatives à
              l'utilisation de vos données personnelles ainsi que celles
              relatives à l'envoi de vos lettres à leurs destinataires
            </label>
          </div>

          {/* Next Button */}
          <button
            type="button"
            className={`p-2 lg:p-3 bg-primaryText text-white font-josefin w-full lg:w-3/12 rounded-3xl self-center mt-5 ${
              !isChecked ? "opacity-50" : ""
            }`}
            onClick={nextHandler}
            disabled={!isChecked}
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
}

export default LegalInfo;
