import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Auth from "./pages/Auth";
import { useContext } from "react";
import AuthContext from "./contexts/AuthCtx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllUsers from "./pages/AdminPages/AllUsers";
import { useEffect } from "react";
import Expedition from "./pages/AdminPages/Expedition";
import PersonalInfo from "./pages/Certification/PersonalInfo";
import LegalInfo from "./pages/Certification/LegalProvision";
import Letter from "./pages/Letter/Letter";
import PaymentSuccess from "./pages/PaymentSuccess/PaymentSuccess";
import PaymentError from "./pages/PaymentError/PaymentError";

function App() {
  const AuthCtx = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(
    () => {
      if (!AuthCtx.userToken && !AuthCtx.isLoggedIn) {
        navigate("/");
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth/:token" element={<Auth />} />
        {AuthCtx.isLoggedIn && AuthCtx.userStatus !== "certified" && (
          <>
            <Route path="/certification" element={<PersonalInfo />} />
            <Route path="/certification/legal" element={<LegalInfo />} />
          </>
        )}

        {AuthCtx.isLoggedIn && AuthCtx.userStatus === "certified" && (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/letter/:selectedLetter" element={<Letter />} />
            <Route path="/success" element={<PaymentSuccess />} />
            <Route path="/error" element={<PaymentError />} />
          </>
        )}

        {AuthCtx.isAdmin && (
          <Route path="/admin/all-users" element={<AllUsers />} />
        )}
        {AuthCtx.isAdmin && <Route path="/admin" element={<AllUsers />} />}
        {AuthCtx.isAdmin && (
          <Route path="/admin/expedition" element={<Expedition />} />
        )}
      </Routes>
    </>
  );
}

export default App;
