import React, { useState, useEffect } from "react";

const InternetConnectionAlert = (props) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        props.onLineHandler(navigator.onLine);
      }
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [props]);

  if (isOnline) {
    return null; // No alert when online
  }

  const alertStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    backgroundColor: "#ff6b6b",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    zIndex: 9999,
  };

  return (
    <div style={alertStyle}>
      <p>
        Vous êtes actuellement hors ligne. Veuillez vérifier votre connexion
        internet
      </p>
    </div>
  );
};

export default InternetConnectionAlert;
