import React, { useState } from "react";
import styles from "./BuyStampsModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { api } from "../../api/api";
import { faCircle as Circle } from "@fortawesome/free-solid-svg-icons";

import stamp from "../../assets/icons/post-stamp.svg";

const PRICE_PER_STAMP = 12.9;

function BuyStampsModal({ onClose, token }) {
  const [numberOfStamps, setNumberOfStamps] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const checkoutHandler = async () => {
    setIsLoading(true);
    try {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      const response = await api.post(
        "/checkout/create",
        {
          numberOfStamps,
          pricePerStamp: PRICE_PER_STAMP,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const session = response.data.session;
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
      if (result.error) {
        toast.error("Quelque chose s'est mal passé, veuillez réessayer");
      }
    } catch (err) {
      toast.error("Quelque chose s'est mal passé, veuillez réessayer");
    } finally {
      setIsLoading(false);
    }
  };

  const featurePoints = [
    {
      title: "Durée illimitée :",
      description:
        "Les timbres et leurs destinataires associés sont valables indéfiniment.",
    },
    {
      title: "Sans frais supplémentaires :",
      description:
        "Aucun coût additionnel n'est requis pour l'envoi de vos lettres.",
    },
    {
      title: "Modifiables à volonté :",
      description:
        "Vous pouvez modifier le destinataire associé à un timbre autant de fois que nécessaire.",
    },
    {
      title: "Récupérable :",
      description:
        "Supprimer un destinataire vous rend le timbre qui lui était associé, vous permettant de le réutiliser ultérieurement.",
    },
  ];

  return (
    <div className={styles.container}>
      <div className="flex overflow-auto gap-5 max-h-[80vh] items-center w-[500px] flex-col bg-white rounded-lg max-[482px]:w-[90vw]">
        <>
          <div className="bg-primaryBack rounded-t-lg text-primaryText w-full font-amaranth text-xl text-center p-3">
            Acheter des timbres
          </div>
          <div className="flex items-center justify-center gap-4 p-5">
            <img src={stamp} className="h-16 w-16" />
            <h2 className="text-xl text-[#545454] font-josefin font-normal">
              Les timbres vous permettent d&apos;ajouter des destinataires à vos
              lettres.
            </h2>
          </div>
          <ul className="space-y-6 p-5">
            <ul className="space-y-6">
              {featurePoints.map((point, index) => (
                <li key={index} className="flex items-start gap-4">
                  <FontAwesomeIcon
                    icon={Circle}
                    className="h-1 w-1 mt-2 flex-shrink-0 fill-current"
                  />
                  <p className="font-[300] font-josefin text-[#545454]">
                    <span className="font-normal font-josefin">
                      {point.title}
                    </span>{" "}
                    : {point.description}
                  </p>
                </li>
              ))}
            </ul>
          </ul>
          <div>
            <div className="flex items-center justify-center gap-3">
              <button
                className="text-pink text-2xl"
                onClick={() => {
                  if (numberOfStamps > 1) {
                    setNumberOfStamps(numberOfStamps - 1);
                  }
                }}
              >
                <FontAwesomeIcon icon={faSquareMinus} />
              </button>
              <h1>{numberOfStamps}</h1>
              <button
                className="text-pink text-2xl"
                onClick={() => setNumberOfStamps(numberOfStamps + 1)}
              >
                <FontAwesomeIcon icon={faSquarePlus} />
              </button>
            </div>
            <p className="text-grey font-josefin text-center mt-4 text-xl">
              Total: {Math.round(numberOfStamps * PRICE_PER_STAMP * 100) / 100}€
            </p>
          </div>
          <div className="flex gap-3 justify-center items-center mb-5">
            <button
              onClick={onClose}
              className="w-1/2 bg-white font-josefin text-primaryText py-2 px-6 rounded-full shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              Fermer
            </button>
            <button
              className="bg-primaryText font-josefin text-white  py-2 px-6 w-1/2 rounded-full shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isLoading}
              onClick={checkoutHandler}
            >
              {isLoading ? "Chargement..." : "Acheter"}
            </button>
          </div>
        </>
      </div>
    </div>
  );
}

export default BuyStampsModal;
