import React, { useContext, useEffect, useState } from "react";
import { SquareLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../contexts/AuthCtx";
import { api } from "../api/api";
import { toast } from "react-toastify"; // Toast library import
import "react-toastify/dist/ReactToastify.css"; // Toast styles

function Auth() {
  const [isLoading, setIsLoading] = useState(true);
  const AuthCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      // Set Authorization header
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      // Call the API to get user info
      api
        .get("auth/getUser")
        .then((data) => {
          // Check if the link is expired
          if (
            data.data.user.loginLinkExpiry &&
            new Date() > new Date(data.data.user.loginLinkExpiry)
          ) {
            // Show toast if the link has expired
            toast.error(
              "Le lien de connexion a expiré. Veuillez en demander un nouveau.",
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
              }
            );
            navigate("/"); // Redirect to home
            return; // Stop further execution
          }

          // Log the user in and store necessary info in Auth Context
          AuthCtx.login(
            token,
            data.data.user.userRole,
            data.data.user.userStatus,
            data.data.user.tokens,
            data.data.user.usedTokens,
            data.data.user.user_info
          );

          // Navigate based on certification status
          if (data.data.user.userStatus === "certified") {
            navigate("/dashboard");
          } else {
            navigate("/certification/legal");
          }
        })
        .catch((err) => {
          // Handle errors (e.g., token issues, server errors)
          toast.error(
            "An error occurred during authentication. Please try again.",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            }
          );
          navigate("/");
        })
        .finally(() => {
          setIsLoading(false); // Ensure loading stops after the request
        });
    } else {
      setIsLoading(false); // If no token, stop loading
    }
  }, [token, AuthCtx, navigate]);

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SquareLoader size={100} color="#775454" loading={isLoading} />
    </div>
  );
}

export default Auth;
