import React from "react";

import styles from "./DeleteConfirmationModal.module.css";
function DeleteConfirmationModal({ onClose, onDelete, title, content }) {
  return (
    <div className={styles.container}>
      <div className="flex gap-5 items-center w-[500px] flex-col bg-white rounded-lg max-[482px]:w-[90vw]">
        <>
          <div className="bg-primaryBack rounded-t-lg text-primaryText w-full font-amaranth text-xl text-center p-3">
            {title}
          </div>
          <p className="text-grey font-josefin text-center w-9/12">{content}</p>
          <div className="flex gap-3 justify-center items-center mb-5">
            <button
              onClick={onClose}
              className="bg-primaryText font-josefin w-1/2 text-white py-2 px-6 rounded-full"
            >
              Non
            </button>
            <button
              onClick={onDelete}
              className="bg-white font-josefin text-primaryText py-2 px-6 w-1/2 rounded-full shadow-md"
            >
              Oui
            </button>
          </div>
        </>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;
