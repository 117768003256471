import "react-toastify/dist/ReactToastify.css";

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  faArrowLeft,
  faCheck,
  faPenToSquare,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

import AuthContext from "../../contexts/AuthCtx";
import BuyStampsModal from "../../components/Dashboard/BuyStampsModal";
import { ClipLoader } from "react-spinners";
import DeleteConfirmationModal from "../../components/Letter/DeleteConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InternetConnectionAlert from "../../components/UX/InternetConnectionAlert";
import JoditEditor from "jodit-react";
import Modal from "../../components/Letter/Modal";
import ReceipientAdded from "../../components/Letter/ReceipientAdded";
import TitleModal from "../../components/Letter/TitleModal";
import { api } from "../../api/api";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import styles from "./Letter.module.css";
import { toast } from "react-toastify";

function Letter(props) {
  const AuthCtx = useContext(AuthContext);
  const { selectedLetter } = useParams();

  const [content, setContent] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isWriting, setIsWriting] = useState(false);

  const [titleModalState, setTitleModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);

  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState("");

  const [changed, setChanged] = useState(false);
  const navigate = useNavigate();

  const editor = useRef(null);
  let messageTimer;

  const updateLetterHandler = async (content) => {
    try {
      setIsWriting(true);
      await api.put(`letter/updateContent/${selectedLetter}`, {
        content,
      });
      setIsWriting(false);
    } catch (error) {
      toast(error.response.data.message);
      setIsWriting(false);
    }
  };

  const getLetter = async () => {
    try {
      const response = await api.get(`/letter/${selectedLetter}`, {
        headers: { Authorization: `Bearer ${AuthCtx.userToken}` },
      });
      const data = response.data;
      return data?.letter;
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedLetter && AuthCtx.userToken) {
        setIsLoading(true);
        const letter = await getLetter();
        if (
          (letter && letter?.content === undefined) ||
          letter?.content === ""
        ) {
          setContent("");
        } else {
          setContent(letter?.content);
        }
        setTitle(letter?.title);
      }
      setIsLoading(false);
    };
    fetchData();
    setTimeout(() => {
      setIsFirstLoad(false);
    }, 3000);

    //eslint-disable-next-line
  }, [selectedLetter, AuthCtx.userToken, changed]);

  useEffect(() => {
    changeHandler(content);
    // eslint-disable-next-line
  }, []);

  const changeHandler = (value) => {
    if (navigator.onLine && !isFirstLoad) {
      updateLetterHandler(value);
      // messageTimer = setTimeout(() => {
      //   toast.success("Sauvegardé", {
      //     position: toast.POSITION.TOP_CENTER,
      //     autoClose: 1000,
      //     hideProgressBar: true,
      //     closeOnClick: true,
      //     closeButton: false,
      //     style: {
      //       maxWidth: "fit-content",
      //     },
      //   });
      // }, 2000);
    }
  };

  const getStyle = () => {
    const width = window.innerWidth;
    if (width < 768) {
      return {
        font: "16px Times New Roman",
        padding: "16px",
      };
    } else if (width < 1024) {
      return {
        font: "16px Times New Roman",
        padding: "30px",
      };
    } else if (width < 1280) {
      return {
        font: "16px Times New Roman",
        padding: "40px",
      };
    } else if (width < 1536) {
      return {
        font: "16px Times New Roman",
        padding: "50px",
      };
    } else {
      return {
        font: "16px Times New Roman",
        padding: "50px",
      };
    }
  };

  const config = useMemo(() => {
    return {
      statusbar: false,
      spellcheck: true,
      grammarly: true,
      autofocus: true,
      toolbar: false,
      minHeight: "90vh",
      width: "100%",
      buttons: ["bold", "italic", "underline", "left", "center", "right"],
      buttonsMD: ["bold", "italic", "underline", "left", "center", "right"],
      buttonsSM: ["bold", "italic", "underline"],
      buttonsXS: ["bold", "italic", "underline"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_only_text",
      placeholder: "",
      style: getStyle(),
      events: {
        beforePaste: (event) => {
          const clipboardData = event.clipboardData || window.clipboardData;
          const pastedData = clipboardData.getData("Text");

          if (clipboardData.types.includes("Files")) {
            // Prevent the default action if image is being pasted
            event.preventDefault();
          }
        },
      },
    };
  }, []);

  useEffect(
    () => {
      //we need to get jodit-wysiwyg through querySelector
      const jodit = document.querySelector(".jodit-wysiwyg");
      if (jodit) {
        const contentHeight = jodit.scrollHeight;
        if (contentHeight > 800) {
          window.scrollTo(0, jodit.scrollHeight - 300);
        } else {
          window.scrollTo(0, 0);
        }
      }
    },
    //eslint-disable-next-line
    [isLoading]
  );

  useEffect(
    () => {
      setIsFirstLoad(true);
    },
    //eslint-disable-next-line
    [selectedLetter]
  );

  const saveHandler = () => {
    changeHandler(content);
  };

  //---------------------------------------------//
  //Handlers

  const titleCloseHandler = () => {
    setTitleModalState(false);
  };

  const deleteCloseHandler = () => {
    setDeleteModalState(false);
    setReipientDeleteModalState(false);
  };

  const deleteLetterHandler = async () => {
    await api
      .delete(`letter/delete/${selectedLetter}`)
      .then(() => {
        deleteCloseHandler();
        AuthCtx.updateHandler();
        navigate("/dashboard");
      })
      .catch((err) => {});
  };

  const titleSubmithandler = (title) => {
    api
      .put(`letter/update/${selectedLetter}`, { title: title })
      .then((res) => {
        setChanged((prev) => !prev);
        toast.success("Lettre mise à jour avec succès");
        setIsLoading(false);
        titleCloseHandler();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
        titleCloseHandler();
      });
  };

  //---------------------------------------------//
  //Recipient Logics

  const [recipients, setRecipients] = useState([]);
  const [recepientChanged, setRecepientChanged] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState();
  const [recepientEditing, setRecepientEditing] = useState(false);
  const [recepientLoading, setRecepientLoading] = useState(false);
  const [recepientModalState, setRecepientModalState] = useState(false);
  const [isBuyStampsModalOpen, setIsBuyStampsModalOpen] = useState(false);
  const [receipientAddedModalState, setReceipientAddedModalState] =
    useState(false);
  const [receipientDeleteModalState, setReipientDeleteModalState] =
    useState(false);

  const recepientCloseHandler = () => {
    setRecepientModalState(false);
  };

  const receipientNewHandler = () => {
    setRecepientEditing(false);
    setRecepientModalState(true);
  };

  const getRecipient = async () => {
    try {
      setRecepientLoading(true);
      const response = await api.get(
        `/letter/getRecipients/${selectedLetter}`,
        {
          headers: { Authorization: `Bearer ${AuthCtx.userToken}` },
        }
      );
      const data = response.data;
      setRecepientLoading(false);
      return data.recipients;
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedLetter && AuthCtx.userToken) {
        setRecepientLoading(true);
        const fetchedRecipient = await getRecipient();

        setRecipients(fetchedRecipient);
      }
      setRecepientLoading(false);
    };
    fetchData();

    //eslint-disable-next-line
  }, [selectedLetter, recepientChanged, AuthCtx.userToken]);

  const modalSubmitHandler = (data) => {
    //we need to check if fields are empty if any of them is empty we need to return a toast
    if (
      !data.title ||
      data.firstName.trim().length === 0 ||
      data.lastName.trim().length === 0 ||
      data.country.trim().length === 0 ||
      data.stAndName.trim().length === 0 ||
      data.postalCode.trim().length === 0 ||
      data.city.trim().length === 0
    ) {
      toast.error("Veuillez remplir tous les champs");
      setRecepientLoading(false);
      return;
    }

    const recipient = data;
    if (recepientEditing) {
      api
        .put(`letter/updateRecipient`, {
          recipient: recipient,
          recipientId: selectedRecipient,
          letterId: selectedLetter,
        })
        .then((res) => {
          setRecepientChanged((prev) => !prev);
          setRecepientLoading(false);
          // e.target.reset();
          setRecepientModalState(false);
          setRecepientEditing(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setRecepientLoading(false);
          setRecepientEditing(false);
        });
    } else {
      api
        .post("letter/addRecipient", {
          recipient: recipient,
          letterId: selectedLetter,
        })
        .then((res) => {
          setRecepientChanged((prev) => !prev);
          setRecepientLoading(false);
          // e.target.reset();
          setRecepientModalState(false);
          setRecepientEditing(false);
          setReceipientAddedModalState(true);
          AuthCtx.updateHandler();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setRecepientLoading(false);
          setRecepientEditing(false);
        });
    }
  };

  const editReceipientHandler = (id) => {
    setRecepientEditing(true);
    setSelectedRecipient(id);
    setRecepientModalState(true);
  };

  const deleteReceipientHandler = () => {
    recipientDeleteHandler();
  };

  const recipientDeleteHandler = async () => {
    try {
      setRecepientLoading(true);
      await api.delete(
        `/letter/deleteRecipient/${selectedLetter}/${selectedRecipient}`
      );
      // toast.success("Utilisateur supprimé avec succès");
      AuthCtx.updateHandler();
      setRecepientChanged((prev) => !prev);
      setRecepientLoading(false);
      setReipientDeleteModalState(false);
      setSelectedRecipient(null);
    } catch (err) {
      toast.error("Une erreur s'est produite");
    }
  };

  //---------------------------------------------//

  return (
    <>
      <InternetConnectionAlert
        onLineHandler={() => {
          saveHandler();
        }}
      />

      {titleModalState && (
        <TitleModal
          isLoading={isLoading}
          isEditing={true}
          id={selectedLetter}
          onFinish={titleSubmithandler}
          onClose={titleCloseHandler}
        />
      )}
      {deleteModalState && (
        <DeleteConfirmationModal
          onClose={deleteCloseHandler}
          onDelete={deleteLetterHandler}
          title="Supprimer cette lettre"
          content={`Êtes-vous certain de vouloir supprimer cette lettre ?
            Les timbres utilisés pour ajouter des destinataires vous seront
            rendus.`}
        />
      )}
      {receipientDeleteModalState && (
        <DeleteConfirmationModal
          onClose={deleteCloseHandler}
          onDelete={deleteReceipientHandler}
          title="Supprimer le destinataire"
          content={`Êtes-vous certain de vouloir supprimer ce destinataire ?
            Les timbres utilisés pour ajouter des destinataires vous seront
            rendus.`}
        />
      )}
      {recepientModalState && (
        <Modal
          isEditing={recepientEditing}
          recipientId={selectedRecipient}
          letterId={selectedLetter}
          isLoading={recepientLoading}
          onSubmit={modalSubmitHandler}
          onClose={recepientCloseHandler}
          onBuyStamps={() => {
            setRecepientModalState(false);
            setIsBuyStampsModalOpen(true);
          }}
        />
      )}
      {isBuyStampsModalOpen && (
        <BuyStampsModal
          onClose={() => setIsBuyStampsModalOpen(false)}
          token={AuthCtx.userToken}
        />
      )}
      {receipientAddedModalState && (
        <ReceipientAdded onClose={() => setReceipientAddedModalState(false)} />
      )}
      {isLoading && (
        <div className="w-100 h-[100vh] flex justify-center items-center">
          <ClipLoader loading={isLoading} color="#CAB9B9" size={40} />
        </div>
      )}
      {!isLoading && (
        <div className="bg-[#FAF1F1] h-full">
          <div className="flex gap-10 sticky top-0 z-10 text-[#545454CC] bg-primaryBack p-5 justify-between items-center">
            <button className="text-2xl" onClick={() => navigate("/dashboard")}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div className="flex gap-3 items-center text-2xl">
              <p className="bg-primaryBack max-w-36  sm:max-w-max overflow-auto  outline-none grow text-center font-amaranth text-2xl">
                {title?.slice(0, 80)}
                {title?.length > 80 && "..."}
              </p>
              <button onClick={() => setTitleModalState(true)}>
                <FontAwesomeIcon
                  className="text-lg"
                  size="sm"
                  icon={faPencil}
                />
              </button>
            </div>
            <div className="flex gap-4 items-center text-2xl">
              {isWriting && <p>...</p>}
              {!isWriting && <FontAwesomeIcon icon={faCheck} />}
              <button onClick={() => saveHandler()}>
                <FontAwesomeIcon icon={faFloppyDisk} />
              </button>
              <button onClick={() => setDeleteModalState(true)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
          <div className={styles.container}>
            <div className="my-6 m-2 sm:mx-0 text-[#545454] font-josefin text-sm text-center font-normal">
              <p>
                Cette lettre sera expédiée automatiquement à ses destinataires
                par voie postale après votre décès.
              </p>
            </div>
            <div className="flex lg:container mx-auto gap-10 flex-col-reverse lg:flex-row">
              <div className="grow"></div>
              <div className="mx-5 lg:mx-auto lg:w-10/12 max-w-[900px]">
                <JoditEditor
                  className={styles.editorClass}
                  ref={editor}
                  value={content}
                  onBlur={(newContent) => setContent(newContent)}
                  onChange={changeHandler}
                  config={config}
                />
              </div>
              <div className="flex lg:sticky lg:top-[90px] rounded-md h-fit bg-white flex-col gap-4 lg:w-[300px] border border-primaryText ">
                <div className="bg-[#775454B2] text-center py-2 px-6 font-josefin text-white ">
                  {recipients?.length} Destinataire(s)
                </div>
                <button
                  onClick={receipientNewHandler}
                  className="bg-pink rounded-full p-2 text-white font-josefin mx-5 flex items-center justify-center gap-2"
                >
                  Ajouter un destinataire
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <div className="flex gap-3 flex-col p-4">
                  {recipients?.map((item) => {
                    return (
                      <div
                        key={item._id}
                        className="flex gap-2 border-b border-[#E4E7EC] items-center justify-between"
                      >
                        <div className="font-josefin text-lg font-medium text-primaryText break-all">
                          {item.firstName.slice(0, 8) +
                            " " +
                            item.lastName.slice(0, 8)}
                          {(item.firstName.length > 8 ||
                            item.lastName.length > 8) &&
                            "..."}
                        </div>
                        <div className="flex gap-3 text-primaryText items-center">
                          <button
                            onClick={() => {
                              editReceipientHandler(item._id);
                            }}
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </button>
                          <button
                            onClick={() => {
                              setSelectedRecipient(item._id);
                              setReipientDeleteModalState(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Letter;
