import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const FAQ = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Comment ByeByeRegrets sera-t-il informé de mon décès ?",
      answer:
        "Nous traitons les données fournies par les autorités publiques. Avec cette information, nous sommes en mesure de vérifier votre statut vital.",
    },
    {
      question: "Y a-t-il des frais associés à mes lettres ?",
      answer:
        "Créer, modifier et supprimer vos lettres est gratuit et illimité. Grâce à des achats ponctuels, vous pouvez ajouter des destinataires à vos lettres afin qu'ils les reçoivent physiquement après votre décès.",
    },
    {
      question:
        "Comment puis-je être sûr que mes lettres seront livrées aux bonnes personnes ?",
      answer:
        "Nous utiliserons les informations précises que vous fournissez pour identifier et localiser les destinataires. Nous enverrons vos lettres par courrier recommandé, garantissant qu'elles sont reçues par les bonnes personnes.",
    },
    {
      question: "Qui peut accéder à mes lettres et à leur contenu ?",
      answer:
        "Personne ne sera au courant de vos lettres avant votre décès. Une fois votre décès confirmé, les lettres seront envoyées au format papier uniquement à leurs destinataires désignés.",
    },
    {
      question:
        "Comment mes données personnelles sont-elles protégées et gérées ?",
      answer:
        "Nos serveurs, situés en France, respectent les normes de confidentialité les plus strictes. Protéger votre vie privée est notre priorité absolue, c'est pourquoi nous nous engageons à ne jamais partager vos informations personnelles avec qui que ce soit.",
    },
    // Ajoutez plus de FAQs ici
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id={props.id} className="max-w-4xl mx-auto p-5 mt-[10vh]">
      <h2 className="text-3xl text-center mb-[10vh] font-amaranth text-primaryText">
        Questions fréquentes
      </h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="pb-6 border-b border-[#EAECF0]">
            <button
              className="w-full font-amaranth text-left flex justify-between items-center text-xl font-medium text-primaryText focus:outline-none"
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span className={`transform transition-transform duration-200`}>
                <FontAwesomeIcon
                  icon={activeIndex === index ? faMinus : faPlus}
                  className="w-3 h-3 text-pink rounded-full border-2 border-pink p-[2px]" 
                />
              </span>
            </button>
            <div
              className={`mt-2 font-josefin text-[#775454CC] leading-7 text-lg transition-all duration-300 overflow-hidden ${
                activeIndex === index ? "max-h-full" : "max-h-0"
              }`}
              style={{ maxHeight: activeIndex === index ? "500px" : "0px" }}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
