import React, { useEffect, useState } from "react";

import styles from "./Header.module.css";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthCtx";
import { Link } from "react-router-dom";
import { api } from "../../api/api";

function Header() {
  const { isAdmin, isLoggedIn, userToken } = useContext(AuthContext);
  const [isPendingUsers, setPendingUsers] = useState(false);

  useEffect(() => {
    if (isAdmin) {
      const fetchData = async () => {
        try {
          const data = await api.get("admin/getUsers", {
            query: {
              certified: false,
            },
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (data.data.users.length > 0) {
            setPendingUsers(true);
          } else {
            setPendingUsers(false);
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchData();
    }
  }, [isAdmin, userToken]);

  return (
    <div className={styles.container}>
      {!isAdmin && <h1>ByeByeRegrets.</h1>}
      <div className={styles.links}>
        {isLoggedIn && <Link to="/dashboard">Dashboard</Link>}
        {isAdmin && (
          <>
            <Link to="/admin/all-users">All Users</Link>

            <Link to="/admin/expedition">Expédition</Link>
          </>
        )}
      </div>
    </div>
  );
}

export default Header;
