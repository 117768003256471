import React, { useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthCtx";
import Header from "../../components/Dashboard/Header";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { updateHandler } = useContext(AuthContext);

  useEffect(() => {
    updateHandler();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="container mx-auto px-10 py-5 h-screen flex flex-col justify-between">
      <Header />
      <div className="flex-1 flex items-center justify-center">
        <div className="flex gap-5 items-center w-[500px] flex-col bg-white rounded-lg max-[482px]:w-[90vw] shadow-md">
          <div className="bg-primaryBack rounded-t-lg text-primaryText w-full font-amaranth text-xl text-center p-3 shadow-sm">
            Succès du paiement
          </div>
          <p className="text-grey font-josefin text-center w-9/12">
            Vous avez effectué le paiement avec succès. Vos timbres seront
            ajoutés à votre compte.
          </p>
          <div className="flex gap-3 justify-center items-center mb-5">
            <button
              onClick={() => navigate("/dashboard")}
              className="bg-primaryText font-josefin text-white py-2 px-6 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Aller au tableau de bord
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
