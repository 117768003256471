import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Home/Header";
import styles from "./Expedition.module.css";
import moment from "moment";
import { api } from "../../api/api";
import AuthContext from "../../contexts/AuthCtx";
import DataTable from "react-data-table-component";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import envelope from "../../components/UX/Icons/envelope.svg"; // Ensure the path is correct
import { generatePDF, generateXLSX } from "../../components/Admin/helper"; // Adjust the path as needed

const columns = [
  {
    name: "Sexe",
    selector: (row) => row.title,
    grow: 0.5,
  },
  {
    name: "Prenom",
    selector: (row) => row.firstName,
    grow: 2,
  },
  {
    name: "Nom",
    selector: (row) => row.birthName,
  },
  {
    name: "Date de naissance",
    selector: (row) => moment(row.date_of_Birth).format("DD/MM/YYYY"),
  },
  {
    name: "Commune de naissance",
    selector: (row) => row.place_of_birth,
  },
  {
    name: "Departement de naissance",
    selector: (row) => row.birth_department_Number,
  },
  {
    name: "Pays de naissance",
    selector: (row) => row.nationality,
  },
  {
    name: "Date de décès",
    selector: (row) => moment(row.deathDate).format("DD/MM/YYYY"),
  },
  {
    name: "Score de mortalité",
    selector: (row) => row.deathScore,
  },
];

function Expedition() {
  const [data, setData] = useState([]);
  const [letters, setLetters] = useState([]);
  const [changed, setChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const AuthCtx = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch users
        const usersResponse = await api.get("admin/getDeathUsers", {
          headers: {
            Authorization: `Bearer ${AuthCtx.userToken}`,
          },
        });

        // Fetch letters
        const lettersResponse = await api.get("admin/getLetters", {
          headers: {
            Authorization: `Bearer ${AuthCtx.userToken}`,
          },
        });

        setData(usersResponse.data.users);
        setLetters(lettersResponse.data.lettersWithDiedUsers);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
        toast.error("Failed to fetch data.");
      }
    };
    fetchData();
  }, [AuthCtx.userToken, changed]);

  const alteredData = data.map((obj) => ({
    _id: obj._id,
    ...obj.user_info,
  }));

  const updateDataHandler = async () => {
    setIsUpdating(true);
    try {
      await api.put(
        "admin/updateDeathStatus",
        {},
        {
          headers: {
            Authorization: `Bearer ${AuthCtx.userToken}`,
          },
        }
      );
      setIsUpdating(false);
      setChanged((prev) => !prev);
      toast.success("Database updated successfully.");
    } catch (err) {
      setIsUpdating(false);
      console.error(err);
      toast.error("Failed to update database.");
    }
  };

  // Define the Expandable Row Component
  const ExpandableRow = ({ data }) => {
    const userLetters = letters?.filter((letter) => {
      return letter?.userId?._id === data._id;
    });

    if (userLetters?.length === 0) {
      return <div className="p-4">No letters found for this user.</div>;
    }

    return (
      <div className="p-4">
        <table className="min-w-full mb-5 border-separate border-spacing-y-4">
          <thead className="bg-gray-200 font-amaranth text-black">
            <tr>
              <th className="px-6 py-3 font-amaranth text-md text-left font-medium">
                Mode d’envoi
              </th>
              <th className="px-6 py-3 font-amaranth text-md text-left font-medium">
                Titre de la lettre
              </th>
              <th className="px-6 py-3 font-amaranth text-md text-left font-medium">
                Nombre de destinataires
              </th>
              <th className="px-6 py-3 font-amaranth text-md text-left font-medium">
                Contenu
              </th>
              <th className="px-6 py-3 font-amaranth text-md text-left font-medium">
                Export Excel
              </th>
            </tr>
          </thead>
          <tbody>
            {userLetters?.map((letter) => (
              <tr key={letter._id} className="bg-white font-josefin text-black">
                <td className="px-6 py-4">
                  <img src={envelope} width={30} height={30} alt="Envelope" />
                </td>
                <td className="px-6 py-4 font-amaranth">{letter.title}</td>
                <td className="px-6 py-4 font-amaranth">
                  {letter.recipients.length}
                </td>
                <td className="px-6 py-4 font-amaranth">
                  <button
                    onClick={() => generatePDF(letter.title, letter.content)}
                    className="text-blue-500 underline"
                  >
                    PDF
                  </button>
                </td>
                <td className="px-6 py-4 font-amaranth">
                  <button
                    onClick={() => generateXLSX(letter.recipients)}
                    className="text-blue-500 underline"
                  >
                    Excel
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className={`${styles.container}`}>
        {isUpdating ? (
          <div className={styles.updatingContaier}>
            <p className={styles.waitMessage}>
              Data Is Updating. Please wait...
            </p>
            <ClipLoader loading={isUpdating} color="#ae67fa" size={30} />
          </div>
        ) : (
          <button onClick={updateDataHandler} className={styles.updateButton}>
            Update Database
          </button>
        )}
        <div className={styles.dataTableContainer}>
          {isLoading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "20px",
              }}
            >
              <ClipLoader loading={isLoading} color="#ae67fa" size={40} />
            </div>
          ) : (
            <DataTable
              title="Liste des Utilisateurs Décédés"
              columns={columns}
              data={alteredData}
              className={styles.dataTable}
              noHeader
              highlightOnHover
              pointerOnHover
              pagination
              expandableRows
              expandableRowsComponent={ExpandableRow}
              customStyles={{
                rows: {
                  style: {
                    fontSize: "14px",
                  },
                },
                headCells: {
                  style: {
                    fontSize: "16px",
                    color: "#3e4581",
                    fontWeight: "600",
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Expedition;
