import React from "react";
import { useNavigate } from "react-router-dom";

function LetterIcon({ title, id, content = "Nouvelle lettre" }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/letter/${id}`);
      }}
      className="shadow-lg rounded-md cursor-pointer w-full h-[240px] md:w-[280px] md:h-[410px]  overflow-hidden "
    >
      <div className="bg-primaryBack line-clamp-1 text-primaryText p-2 font-amaranth font-medium text-lg ">
        {title?.slice(0, 25)}
        {title?.length > 25 && "..."}
      </div>
      <div
        className="p-3 text-[6.5px] h-[350px] text-grey break-words line-clamp-[20] md:line-clamp-[35]"
        dangerouslySetInnerHTML={{
          __html: `${content} `,
        }}
      ></div>
    </div>
  );
}

export default LetterIcon;
