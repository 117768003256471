import React from "react";

import styles from "./DeleteConfirmationModal.module.css";
import mailbox from "../UX/Icons/mailbox.svg";

function ReceipientAdded({ onClose }) {
  return (
    <div className={styles.container}>
      <div className="flex gap-5 items-center w-[500px] flex-col bg-white rounded-lg max-[482px]:w-[90vw]">
        <>
          <div className="bg-primaryBack rounded-t-lg text-primaryText w-full font-amaranth text-xl text-center p-3">
            Destinataire ajouté
          </div>
          <div className="flex gap-3 justify-center items-center">
            <img src={mailbox} width={200} height={200} alt="Mailbox Icon" />
          </div>
          <p className="text-grey font-josefin p-5">
            Un exemplaire de votre lettre a été placé dans la boîte d’envoi et
            sera envoyé à ce destinataire à votre décès.
            <br />
            L’exemplaire sera mis à jour si vous modifez le contenu de la
            lettre.
          </p>
          <div className="flex gap-3 justify-center items-center mb-5">
            <button
              onClick={onClose}
              className="bg-primaryText font-josefin text-white py-2 px-6 rounded-full"
            >
              D’accord
            </button>
          </div>
        </>
      </div>
    </div>
  );
}

export default ReceipientAdded;
