import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const transformRecipientsData = (recipients) => {
  return recipients.map((recipient) => ({
    Title: recipient.title,
    FirstName: recipient.firstName,
    LastName: recipient.lastName,
    Country: recipient.country || "",
    PostalCode: recipient.postalCode || "",
    City: recipient.city || "",
    Locality: recipient.locality || "",
    Address: recipient.address || "",
    StreetAndName: recipient.stAndName || "",
  }));
};

const convertHTMLToFormattedText = (html) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;

  // Process the HTML elements
  const formattedText = Array.from(tempElement.childNodes)
    .map((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        switch (node.tagName) {
          case "H1":
            return `${node.innerText.toUpperCase()}\n`; // Add line breaks for H1
          case "H2":
            return `${node.innerText}\n`; // Add line break for H2
          case "P":
            return `${node.innerText}\n`; // Add line breaks for paragraphs
          case "B":
          case "STRONG":
            return `${node.innerText} `; // Bold text
          case "I":
          case "EM":
            return `${node.innerText} `; // Italic text
          default:
            return `${node.innerText} `; // Other elements
        }
      }
      return "";
    })
    .join("");

  return formattedText.trim();
};

export const generatePDF = (title, content) => {
  const formattedText = convertHTMLToFormattedText(content);
  const pdf = new jsPDF("p", "mm", "a4");
  const margin = 15; // Set margins for the PDF
  const pageHeight = pdf.internal.pageSize.height;
  const pageWidth = pdf.internal.pageSize.width;

  // Set font size
  pdf.setFontSize(12);

  // Split the formatted text into lines for wrapping
  const splitText = pdf.splitTextToSize(formattedText, pageWidth - margin * 2);

  let heightLeft = pageHeight - margin * 2; // Adjust height for margins
  let positionY = margin; // Starting position for text

  splitText.forEach((line, index) => {
    if (positionY + 10 > pageHeight - margin) {
      // Check if the line fits on the page
      pdf.addPage(); // Create a new page if it doesn't fit
      positionY = margin; // Reset position for the new page
    }

    // Add the line to the PDF
    pdf.text(line, margin, positionY);
    positionY += 10; // Increment position for the next line
  });

  pdf.save(`${title}.pdf`);
};

export const generateXLSX = (data) => {
  const transformedRecipients = data.map((recipient, index) => ({
    RaisonSociale: "", // Assuming this is left empty
    "*Civilité (autorisée:'M.ou'  'Mme' )":
      recipient.title === "male" ? "M." : "Mme", // Assigning title based on index (0 for male, 1 for female)
    "*Nom": recipient.lastName,
    "*Prenom": recipient.firstName,
    "Complément d’adresse (bâtiment, appartement, étage, chez,…)":
      recipient.address,
    "*N° et libellé de voie": recipient.stAndName,
    "Lieu-dit/BP": recipient.locality,
    "*Code postal": recipient.postalCode,
    "*Ville": recipient.city,
    "*Pays": recipient.country,
  }));

  const ws = XLSX.utils.json_to_sheet(transformedRecipients);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Recipients");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "recipients.xlsx");
};
