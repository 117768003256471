import React from 'react'
import { Link } from "react-router-dom";

import styles from './Footer.module.css';

function Footer() {
    return (
        <>
            <div className={styles.container}>
                <h2 className={styles.heading}>ByeByeRegrets.</h2>
                <div className={styles.links}>
                    <Link to="/">CGU</Link>
                    <Link to="/">CGV</Link>
                    <Link to="/">Politique de confidentialité</Link>
                </div>
            </div>
            <p className={styles.copyrightTxt}>Copyright © ByeByeRegrets.</p>
        </>
    )
}

export default Footer