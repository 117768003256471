import React, { useContext, useEffect, useState } from "react";
import styles from "./Modal.module.css"; // Keep custom CSS import
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import customStamp from "../UX/Icons/customStamp.svg";
import customStampB from "../UX/Icons/customStampB.svg";
import { api } from "../../api/api";
import AuthContext from "../../contexts/AuthCtx";

const style = {
  input:
    "px-2 py-3 w-full text-grey font-josefin border-[#D1D5DB] border outline-none rounded-md",
};

function Modal(props) {
  const { isEditing, recipientId, letterId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isVibrating, setIsVibrating] = useState(false);
  const AuthCtx = useContext(AuthContext);
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    country: "",
    stAndName: "",
    postalCode: "",
    city: "",
    locality: "",
    address: "",
  });

  // Fetch existing recipient data if editing
  useEffect(() => {
    const fetchData = async () => {
      if (isEditing) {
        setIsLoading(true);
        const response = await api.get(
          `letter/getRecipient/${letterId}/${recipientId}`
        );
        setFormData(response.data.recipient);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [isEditing, letterId, recipientId]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    let updatedValue = value;
    setFormData((prevData) => ({
      ...prevData,
      [id]: updatedValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEditing) {
      if (AuthCtx.totalStamps === AuthCtx.usedStamps) {
        setIsVibrating(true);
        setTimeout(() => setIsVibrating(false), 1000);
        return;
      }
    }
    props.onSubmit(formData);
  };

  return (
    <div className={styles.container}>
      <form
        onSubmit={handleSubmit}
        className="flex w-[90vw] sm:w-[80vw] lg:w-1/2 max-w-3xl flex-col gap-5 items-center bg-white rounded-lg max-h-[80vh] overflow-auto" // Responsive width and max-height
      >
        <div className="bg-primaryBack rounded-t-lg text-primaryText w-full font-amaranth text-xl text-center p-3">
          {isEditing ? "Modifier le destinataire" : "Ajouter un destinataire"}
        </div>

        {isLoading ? (
          <div className="flex w-full h-full items-center justify-center mt-5">
            <ClipLoader
              className={styles.loadingSpinner}
              loading={isLoading}
              color="#fff"
              size={30}
            />
          </div>
        ) : (
          <div className={styles.formContent}>
            <p className="text-grey font-josefin text-center sm:text-left">
              Un destinataire est une personne à qui nous enverrons votre lettre
              après votre décès. Si vous le supprimez, le timbre que vous aurez
              utilisé vous sera rendu.
            </p>

            <select
              id="title"
              className={style.input}
              value={formData.title}
              onChange={handleInputChange}
            >
              <option value="" disabled hidden>
                Civilité*
              </option>
              <option value="male">Monsieur</option>
              <option value="female">Madame</option>
            </select>

            <input
              required
              id="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Nom *"
              className={style.input}
            />

            <input
              required
              id="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Prénom *"
              className={style.input}
            />

            <input
              required
              id="country"
              type="text"
              value={formData.country}
              onChange={handleInputChange}
              placeholder="Pays*"
              className={style.input}
            />
            <input
              required
              id="stAndName"
              type="text"
              value={formData.stAndName}
              onChange={handleInputChange}
              placeholder="N° et libellé de la voie*"
              className={style.input}
            />

            <div className="flex gap-3 justify-center items-center sm:flex-row flex-col w-full">
              <input
                required
                id="postalCode"
                type="text"
                value={formData.postalCode}
                onChange={handleInputChange}
                placeholder="Code postal*"
                className={style.input}
              />
              <input
                required
                id="city"
                type="text"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Ville*"
                className={style.input}
              />
            </div>

            <input
              id="locality"
              type="text"
              value={formData.locality}
              onChange={handleInputChange}
              placeholder="Lieu-dit ou BP"
              className={style.input}
            />
            <input
              id="address"
              type="text"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Complément d’adresse (bâtiment, appartement, étage, chez,...)"
              className={style.input}
            />

            {!isEditing && (
              <div className="flex gap-3 justify-center items-center">
                <img
                  src={customStampB}
                  width={30}
                  height={30}
                  alt="Stamp Icon"
                  className={`${isVibrating ? "animate-vibrate" : ""}`}
                />
                <p className="text-grey font-josefin text-xl">
                  {AuthCtx.totalStamps - AuthCtx.usedStamps}
                </p>
                <FontAwesomeIcon
                  icon={faSquarePlus}
                  className="cursor-pointer text-pink text-2xl"
                  onClick={() => props.onBuyStamps()}
                />
              </div>
            )}

            <div className="flex flex-col sm:flex-row gap-3 justify-center items-center mb-5 w-full">
              <button
                type="submit"
                className="flex gap-3 justify-center items-center bg-primaryText text-white py-2 px-6 rounded-full w-full sm:w-auto"
              >
                {isEditing ? "sauvegarder" : "Ajouter"}
                {!isEditing && (
                  <span className="text-small flex gap-1 items-center">
                    -1
                    <img
                      src={customStamp}
                      width={20}
                      height={20}
                      alt="Stamp Icon"
                    />
                  </span>
                )}
              </button>
              <button
                type="button"
                onClick={props.onClose}
                className="bg-white text-primaryText py-2 px-6 rounded-full shadow-md w-full sm:w-auto"
              >
                Fermer
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default Modal;
